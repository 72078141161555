import Axios from "axios";

export const BACKEND = process.env.REACT_APP_BACKEND;
export const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;
export const BACKEND_WS = process.env.REACT_APP_BACKEND_WS;
export const URL_BACKEND = BACKEND + ":" + BACKEND_PORT + "/";
export const URL_BACKEND_WS = BACKEND_WS + ":" + BACKEND_PORT + "/";


export const ROUTES = {
  LOGIN: "login",
  OUTPUT: "output",
  AVATAR_WITHOUT_FILE: "avatarwithoutfile",
  AVATAR: "avatar",
  AVATAR_SETTINGS: "avsettings",
  VOICES: "voices",
  JOB: "job",
  SSML: "ssml",
  TRANSLATE:"translate",
  DETECT_LANG:"detectLang",
  USER: "user",
  ORGANIZATION: "organization",
  TRACK:"track",
  SHORT_URL: "short_url",
  TTS: "tts",
  BACKGROUND: "background",
  PAGE_CONTENT: "page_content",
  TERMS_AND_CONDITIONS: "terms_and_conditions",
  PENDING: "pending_jobs",
  ANALYTICS: "analytics",
  VALID_TOKEN: "valid_token",

  PUBLIC: "/public",
  LIST: "/list",
  SHARE: "/share",
  GROUP: "/group"
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: URL_BACKEND,
});
