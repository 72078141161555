import { createSlice } from "@reduxjs/toolkit";
import { AXIOS_INSTANCE, ROUTES, URL_BACKEND } from "../../../configs/routing";
import { history } from "../../../history";
import { asyncHelperActions } from "../ayncHelper/asyncHelperSlice";
import { COMPONENTS } from "../../../configs/components";
import { batch } from "react-redux";
import { LOCAL_STORAGE } from "../../../configs/localstorage";
import { MyAccountSlice } from "../myAccount/slice";
import myAccount from "../myAccount";

const slice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    username: null,
    userRole: null,
    userOrganization: null,
    userOrganizationName: null,
    userUID: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.userRole = action.payload.userRole;
      state.userOrganization = action.payload.userOrganization;
      state.userOrganizationName = action.payload.userOrganizationName;
      state.userUID = action.payload.userUID;
    },
    clearUserDetails: (state) => {
      state.username = null;
      state.token = null;
      state.userRole = null;
      state.userOrganization = null;
      state.userOrganizationName = null;
      state.userUID = null;
    },
  },
});

export const handleLogin = ({
  email,
  password,
  remember /*@TODO remember user*/,
}) => {
  return (dispatch) => {
    dispatch(
      asyncHelperActions.setLoading({ componentName: COMPONENTS.LOGIN })
    );
    return AXIOS_INSTANCE.post(URL_BACKEND + ROUTES.LOGIN, {
      username: email,
      password,
    })
      .then((response) => {
        const { data } = response;
        const newUserDetails = {
          //auth
          token: data.token,
          userRole: data.user.role,
          userOrganization: data.user.organization_uid,
          userOrganizationName: data.user.organization_name,
          userUID: data.user.uid,
          username: email,
        };
        localStorage.setItem(
          LOCAL_STORAGE.AUTH_STORE,
          JSON.stringify(newUserDetails)
        );

        const newAccountDetails = data.user; //myAccount
        localStorage.setItem(
          LOCAL_STORAGE.MY_ACCOUNT,
          JSON.stringify(newAccountDetails)
        );


        batch(() => {
          dispatch(slice.actions.setUserDetails(newUserDetails)); //auth

          dispatch(
            //myAccount
            MyAccountSlice.actions.setAccount({ account: newAccountDetails })
          );

          dispatch(
            asyncHelperActions.setSuccess({ componentName: COMPONENTS.LOGIN })
          );
        });
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          e.message = "The server could not be reached";
        } else if (e.response.status === 401) {
          e.message = "Invalid username or password. Please try again";
        }

        dispatch(
          asyncHelperActions.setError({
            componentName: COMPONENTS.LOGIN,
            message: e.message,
          })
        );
        throw e;
      });
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch(slice.actions.clearUserDetails());
    dispatch(MyAccountSlice.actions.clearAccount());

    dispatch(myAccount.clearAccount());

    localStorage.removeItem("guide_education_auth");
    history.push("/login");
  };
};

export const login = slice.reducer;
