export const COMPONENTS = {
  LOGIN: "LOGIN",
  NEW_JOB: "NEW_JOB",
  NEW_AVATAR: "NEW_AVATAR",
  NEW_BACKGROUND: "NEW_BACKGROUND",
  LIST_JOB:"LIST_JOB",
  LIST_AVATAR:"LIST_AVATAR",
  LIST_VIDEO:"LIST_VIDEO",
  LIST_USER:"LIST_USER",
  LIST_SETTING:"LIST_SETTING",
  LIST_BACKGROUND:"LIST_BACKGROUND",
  LIST_ANALYTICS:"LIST_ANALYTICS",
  SHARE_VIDEO:"SHARE_VIDEO",
  SHARE_AVATAR:"SHARE_AVATAR",
  SHOW_HOW_TO:"SHOW_HOW_TO",
  EDIT_HOW_TO:"EDIT_HOW_TO",
  SHOW_ToC:"SHOW_ToC",
  EDIT_ToC:"EDIT_ToC",
};
