import { createSlice } from "@reduxjs/toolkit";

// State can be "mutated" because of Immer (part of Redux Toolkit) https://github.com/immerjs/immer
export const UserSlice = createSlice({
  name: "user",
  initialState: {
    user:null,
    userList:null,
  },
  reducers: {
    setUserList: (state, action) => {
      state.users = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});
