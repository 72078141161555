import { createSlice } from "@reduxjs/toolkit";

export const MyAccountSlice = createSlice({
  name: "myAccount",
  initialState: {
    account: null,
  },
  reducers: {
    clearAccount: (state) => {
      state.account = null;
    },
    setAccount: (state, action) => {
      state.account = { ...action.payload.account };
    },
  },
});
