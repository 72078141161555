import Axios from "axios";

export const getUserList = () => {
  return async (dispatch) => {
    await Axios.get("http://localhost:5001/getUserList")
      .then((response) => {
        var userList;
        if (response.data) {
          userList = response.data.auth_users;
          dispatch(slice.actions.setUserList(userList));
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

// export const user = (state = {}, action) => {
//   switch (action.type) {
//     case "GET_USER": {
//       return { ...state, user: action.value };
//     }
//     case "CHANGE_USER": {
//       return { ...state, user: action.value };
//     }
//     default: {
//       return state;
//     }
//   }
// };
