import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "modal",
  initialState: {
    isActive: {},
  },
  reducers: {
    setmodal: (state, action) => {
      state.isActive[action.payload.modalID] = action.payload.value;
    },
    clearmodal: (state) => {
      state.isActive = {};
    },
  },
});

export const modalActions = slice.actions;
export const modalReducer = slice.reducer;
