import themeConfig from "../../../configs/themeConfig";

export const customizerReducer = (state = themeConfig, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, theme: action.mode };
    case "COLLAPSE_SIDEBAR":
      return { ...state, sidebarCollapsed: action.value };
    case "CHANGE_NAVBAR_COLOR":
      return { ...state, navbarColor: action.color };
    case "CHANGE_NAVBAR_TYPE":
      return { ...state, navbarType: action.style };
    case "CHANGE_FOOTER_TYPE":
      return { ...state, footerType: action.style };
    case "CHANGE_MENU_COLOR":
      return { ...state, menuTheme: action.style };
    case "HIDE_SCROLL_TO_TOP":
      return { ...state, hideScrollToTop: action.value };
    default:
      return state;
  }
};

export const changeMode = (mode) => {
  return (dispatch) => dispatch({ type: "CHANGE_MODE", mode });
};

export const collapseSidebar = (value) => {
  return (dispatch) => dispatch({ type: "COLLAPSE_SIDEBAR", value });
};

export const changeNavbarColor = (color) => {
  return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_COLOR", color });
};

export const changeNavbarType = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_NAVBAR_TYPE", style });
};

export const changeFooterType = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_FOOTER_TYPE", style });
};

export const changeMenuColor = (style) => {
  return (dispatch) => dispatch({ type: "CHANGE_MENU_COLOR", style });
};

export const hideScrollToTop = (value) => {
  return (dispatch) => dispatch({ type: "HIDE_SCROLL_TO_TOP", value });
};
