import React from "react";
import * as Icon from "react-feather";
import {ROLES} from "./roles"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.translator, ROLES.superAdmin],
    projectEnable: false,
    navLink: "/",
  },
  {
    id: "outputList",
    title: "Outputs",
    type: "item",
    icon: <Icon.Eye size={20} />,
    permissions: [ROLES.admin, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/output",
  },
  // {
  //   id: "videoList",
  //   title: "Videos",
  //   type: "item",
  //   icon: <Icon.List size={20} />,
  //   permissions: [ROLES.admin, ROLES.edition, ROLES.translator, ROLES.superAdmin],
  //   projectEnable: true,
  //   navLink: "/org/video",
  // },
  {
    id: "videoGroupList",
    title: "Videos",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.translator, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/video",
  },
  {
    id: "settingsList",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/setting",
  },
  {
    id: "backgroundList",
    title: "Backgrounds",
    type: "item",
    icon: <Icon.Image size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/background",
  },
  {
    id: "avatarList",
    title: "Avatars",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/avatar",
  },
  {
    id: "userList",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: [ROLES.admin, ROLES.edition, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/user",
  },
  {
    id: "organizationList",
    title: "Organizations",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: [ROLES.admin, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/organization",
  },
  {
    id: "howToPage",
    title: "How To",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: [ROLES.admin,  ROLES.edition, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/page_content?key=how_to",
  },
  {
    id: "termsAndConditions",
    title: "Terms and Conditions",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: [ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/terms-and-conditions/edit?key=toc",
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    icon: <Icon.BarChart2 size={20} />,
    permissions: [ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/analytics",
  }
];

if (true) {
  navigationConfig.splice(3,0,{
    id: "translateList",
    title: "Translators",
    type: "item",
    icon: <Icon.Globe size={20} />,
    permissions: [ROLES.translator, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/translate",
  })
}

export default navigationConfig;
