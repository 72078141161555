import { createSlice } from "@reduxjs/toolkit";

// State can be "mutated" because of Immer (part of Redux Toolkit) https://github.com/immerjs/immer
export const InfoSlice = createSlice({
  name: "info",
  initialState: {
    infoPendingJobs:null,
  },
  reducers: {
    setInfoPendingJobs: (state, action) => {
      state.infoPendingJobs = action.payload;
    },
    clearInfo: (state) => {
      state.infoPendingJobs = null;
    },
    getInfoPendingJobs: (state) => {
      let actualInfoPendingJobs =  state.infoPendingJobs
      state.infoPendingJobs = null;
      return actualInfoPendingJobs; 
    },
  },
});
