import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Media,
  Badge,
} from "reactstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";
import { useDispatch } from "react-redux";
import { handleLogout } from "../../../redux/slices/auth/loginReducer";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const dispatch = useDispatch();

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={() => dispatch(handleLogout())}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">{props.login ? "Login" : "Log Out"}</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const ToggleSidebarMenu = (props) => {
  props.sidebarVisibility();
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult });
    // });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <div className="display-contents">
        <ul className="nav navbar-nav navbar-nav-user float-left">
          <h2 className={"content-header-title float-left mb-0"}>{this.props.title}</h2>
        </ul>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.props.userName}
                </span>
                {typeof this.props.userName === "undefined" ? null : (
                  <span className="user-status">{this.props.organizationName}</span>
                )}
              </div>
              {this.props.deviceWidth < 1200 ? 
              <Button color="" onClick={() => ToggleSidebarMenu(this.props)}>
                <Icon.Menu />
              </Button>
              : null }
              <span data-tour="user">
                <Icon.Settings />
              </span>
            </DropdownToggle>
            <UserDropdown {...this.props} login={typeof this.props.userName === "undefined"} />
          </UncontrolledDropdown>
        </ul>
      </div>
    );
  }
}
export default NavbarUser;
