const initialState = {
  suggestions: [],
  isLoading: false,
  value: "",
  noSuggestions: false,
  extraStarred: [],
  title: "",
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MAYBE_UPDATE_SUGGESTIONS":
      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false,
      };
    case "UPDATE_TITLE":
      return {
        ...state,
        title: action.title,
      };
    default:
      return state;
  }
};


export default navbarReducer;
