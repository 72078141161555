import React from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { ArrowUp } from "react-feather";
import classnames from "classnames";
import preval from 'preval.macro'

const Footer = (props) => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      <div className="d-flex justify-content-between">
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            <a
              href="https://www.guideeducation.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GuideEducation.co.uk
            </a>
          </span>
        </p>
        {window.location.pathname === "/" ? (
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
              Build: {preval`module.exports = new Date().toLocaleString();`}.
          </span>
        </p>
        ) : null}

      </div>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  );
};

export default Footer;
