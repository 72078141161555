// import React from "react"
// import * as Icon from "react-feather"
import navigationConfig from "../configs/navigationConfig";

// const horizontalMenuConfig = [
//   {
//     id: "home",
//     title: "Home",
//     type: "item",
//     icon: <Icon.Home size={20} />,
//     permissions: ["admin", "editor"],
//     navLink: "/",
//   },
//   {
//     id: "dashboard",
//     title: "Dashboard",
//     type: "item",
//     icon: <Icon.File size={20} />,
//     permissions: ["admin", "editor"],
//     navLink: "/dashboard",
//   }
// ]
const horizontalMenuConfig = navigationConfig;

export default horizontalMenuConfig;
